import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "responses"

class Response{
    constructor(id, form, sections){
        this.id = id
        this.form = form
        this.sections = sections
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let res = await getDocs(collectionRef)

        let responses = []

        res.forEach(doument => {
            let data = doument.data()
            let tmp_Response = new Response(doument.id, data.form, data.sections)
            responses.push(tmp_Response)
        })

        return responses
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let res = await getDoc(documentRef)

        let data = res.data()
        let response = new Response(res.id, data.form, data.sections)

        return response
    }

    static async getByForm(formId){
        if(formId == null){
            return []
        }else{
            let collectionRef = collection(db, collectionName)
            let documentQuery = query(collectionRef, where("form", "==", formId))
            let res = await getDocs(documentQuery)
    
            let responses = []
    
            res.forEach(document => {
                let data = document.data()
                let tmp_Response = new Response(document.id, data.form, data.sections)
                responses.push(tmp_Response)
            })
            return responses
        }
    } 
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let responses = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_Response = new Response(document.id, data.form, data.sections)
                responses.push(tmp_Response)
            })
            
            if(callback != null){
                callback(responses)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let response = new Response(snapshot.id, data.form, data.sections)
            
            if(callback != null){
                callback(response)
            }
        })
        return unsub
    }

    static listenByForm(formId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("form", "==", formId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let responses = []

            snapshot.forEach(document => {
                    let data = document.data()
                    let response = new Response(document.id, data.form, data.sections)
                    responses.push(response)
                }
            )
            
            if(callback != null){
                callback(responses)
            }
        })
        return unsub
    }

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let res = await addDoc(collectionRef, {
                form: this.form,
                sections: this.sections,
            })
            this.id = res.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                form: this.form,
                sections: this.sections,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Response